import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    outline: {
      field: {
        fontSize: 'md',
        size: 'lg',
        paddingX: 'md',
        paddingY: 'sm',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
};

export default Input;
