
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
/* eslint-disable import/order */
import { useCallback } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import { AnimatePresence } from 'framer-motion';
import Head from 'next/head';
import { getSession, SessionProvider } from 'next-auth/react';
import './PasswordStrengthMeter.css';
import '@/components/DateInput/DateInput.css';
import '@/components/Languages/LanguageSelector.css';
import '@/components/Analytics/CultureScoreCard.css';
import '@/components/Analytics/ProjectScope.css';
import '@/views/survey/ParticipantJourney.css';

import config from '@/config';
import { AuthProvider } from '@/context/AuthContext';
import { useApollo } from '@/graphql/apollo';
import { CustomSession } from '@/server/models/auth';
import theme from '@/theme';
import { SidebarProvider } from '@/components/Layout/Sidebar/SidebarContext';

const __Page_Next_Translate__ = function App({
  Component,
  pageProps: { session: sessionProp, ...pageProps },
}): JSX.Element {
  const getAuthToken = useCallback(async () => {
    const session = (await getSession()) as CustomSession;
    return session?.idToken || null;
  }, []);

  const apolloClient = useApollo(pageProps.initialApolloState, getAuthToken);

  return (
    <Sentry.ErrorBoundary>
      <SidebarProvider>
        <ApolloProvider client={apolloClient}>
          <SessionProvider session={sessionProp}>
            <AuthProvider clearCache={() => apolloClient.clearStore()}>
              <ChakraProvider resetCSS theme={theme}>
                <Head>
                  <title>{config.title}</title>
                  <meta
                    content='width=device-width, initial-scale=1'
                    name='viewport'
                  />
                  <meta content='ie=edge' httpEquiv='x-ua-compatible' />
                </Head>
                <AnimatePresence exitBeforeEnter>
                  <Component {...pageProps} />
                </AnimatePresence>
              </ChakraProvider>
            </AuthProvider>
          </SessionProvider>
        </ApolloProvider>
      </SidebarProvider>
    </Sentry.ErrorBoundary>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  