import { ComponentStyleConfig } from '@chakra-ui/react';

const Modal: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      backdropFilter: 'blur(30px)',
      background:
        'radial-gradient(97.57% 210.75% at 0.9% 2.98%, rgba(49, 130, 206, 0.4) 0%, rgba(255, 255, 255, 0) 100%)',
      transform: 'rotate(180deg)',
    },
  },
};

export default Modal;
