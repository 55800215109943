const colors = {
  gray: {
    100: '#EDF2F7',
    500: '#718096',
    700: '#2D3748',
  },
  raspberryRed: {
    500: '#bc204b',
    600: '#bc204b99',
    800: '#bc204bcc',
  },
  electricBlue: {
    500: '#0072ce',
    600: '#0072ce99',
    800: '#0072cecc',
  },
  pokerPrimrose: {
    500: '#ba9cc5',
    600: '#ba9cc599',
    800: '#ba9cc5cc',
  },
  mistleToe: {
    500: '#bce194',
    600: '#bce19499',
    800: '#bce194cc',
  },
  goldEarth: {
    500: '#d7a179',
    600: '#d7a17999',
    800: '#d7a179cc',
  },
  burntOrange: {
    500: '#f2a900',
    600: '#f2a90099',
    800: '#f2a900cc',
  },
  '188C': '#76232F',
  '534C': '#1B365D',
  '668C': '#614B79',
  '575C': '#67823A',
  '876C': '#8B634B',
  '126C': '#9A7611',
  silver: '#C7C9C7',
  charcoal: '#3D3935',
  plainBackground: '#FFFEFC',
  darkPink: 'rgba(203, 30, 81, 1)',
  lightGray: 'rgba(226, 232, 240, 1)',
  darkGray: 'rgba(113, 128, 150, 1)',
  lightBlack: 'rgba(45, 55, 72, 1)',
  lightBlue: '#0088F6',
  darkBlue: 'rgba(67, 105, 137, 0.50)',
  lightRed: '#CB1E51',
};

export default colors;
