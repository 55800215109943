export default {
  env: process.env.NODE_ENV,
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  prismic: {
    repo: process.env.NEXT_PUBLIC_PRISMIC_REPO,
    accessToken: process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN,
  },
  prodDomain: process.env.NEXT_PUBLIC_PROD_DOMAIN,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  title: 'ZRG - Taylor Assessment',
  tileColor: '#ffce00',
  firebaseConfig:
    process.env.NEXT_PUBLIC_FIREBASE_CONFIG &&
    JSON.parse(
      Buffer.from(
        `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
        'base64'
      ).toString()
    ),

  // Server-side config (not exposed to the client)
  nextAuthUrl: process.env.NEXTAUTH_URL,
  nextAuthJwtSecret: process.env.NEXTAUTH_JWT_SECRET,
  firebaseWebApiKey: process.env.FIREBASE_WEB_API_KEY,
  surveryId: process.env.NEXT_PUBLIC_SURVEY_ID,
};
