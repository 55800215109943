/* eslint-disable import/order */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { signIn as nextSignIn, signOut as nextSignOut } from 'next-auth/react';

import { User, UserRole, useSelfLazyQuery } from '@/generated/core.graphql';
import useCustomSession from '@/hooks/customSession';
import { CustomSession } from '@/server/models/auth';

interface IAuthContext {
  isLoading: boolean;
  isAuthenticated: boolean;
  isSigningIn: boolean;
  session?: CustomSession;
  self?: User | undefined;
  selfError?: string;
  roles?: UserRole[] | undefined;
  userRole?: UserRole;
  signInError?: string;
  signIn: (email: string, password: string) => Promise<boolean>;
  signOut: () => void;
  refetchSelf: () => void;
}

const AuthContext = React.createContext<IAuthContext>({
  isLoading: true,
  isAuthenticated: false,
  isSigningIn: false,
  signIn: () => null,
  signOut: () => null,
  refetchSelf: () => null,
});

function AuthProvider({
  children,
  clearCache,
}: {
  clearCache: () => void;
  children: React.ReactNode;
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState<string | undefined>('');

  const router = useRouter();
  const session = useCustomSession();
  const sessionUser = session.data?.user;

  const [getSelf, { data: selfData, error: selfError, refetch }] =
    useSelfLazyQuery();

  useEffect(() => {
    setIsAuthenticated(!!sessionUser && !!sessionUser);
  }, [sessionUser]);

  useEffect(() => {
    setIsAuthenticated(!!sessionUser && !!sessionUser);

    if (isAuthenticated && !selfData?.self) {
      getSelf();
    }
  }, [sessionUser, isAuthenticated, selfData, getSelf]);

  const signIn = useCallback(async (email: string, password: string) => {
    setSignInError(undefined);
    setIsSigningIn(true);

    try {
      const result = await nextSignIn('credentials', {
        username: email,
        password,
        // handle signin response without redirect
        // (available only for credentials and email providers)
        redirect: false,
      }); // adds CSRF token automatically
      // eslint-disable-next-line no-console
      console.log('signin response:', result);

      if (!result.ok || result.error) {
        setSignInError('Invalid credentials. Check your email or password.');
        return false;
      }

      await getSelf();

      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('signin error:', err);
      setSignInError(err.message);
      return false;
    } finally {
      setIsSigningIn(false);
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await nextSignOut({
        redirect: false, // stay on this page
      });
      clearCache();
      getSelf();

      router.push('/auth/signin');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('signout error:', err);
    }
  }, []);

  const getUserRole = () => {
    const roles = sessionUser?.roles;
    if (roles?.includes(UserRole.Admin)) {
      return UserRole.Admin;
    }
    if (roles?.includes(UserRole.Consultant)) {
      return UserRole.Consultant;
    }
    if (roles?.includes(UserRole.Client)) {
      return UserRole.Client;
    }
    return UserRole.User;
  };

  const refetchSelf = useCallback(async () => {
    if (refetch) {
      await refetch();
    }
  }, [refetch]);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isLoading: session.isLoading || isSigningIn,
        isAuthenticated,
        isSigningIn,
        session: session.data,
        self: selfData?.self as User,
        selfError: selfError?.message,
        roles: sessionUser?.roles || [],
        userRole: getUserRole(),
        signInError,
        signIn,
        signOut,
        refetchSelf,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }
  return context;
}

export { AuthProvider, useAuth };
