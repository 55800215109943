import React, { createContext, useMemo, useState } from 'react';

interface ISidebarContext {
  isOpen: boolean;
  toggleSidebar(): void;
}

const SidebarContext = createContext<ISidebarContext>({
  isOpen: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleSidebar: () => {},
});

function SidebarProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const contextValue = useMemo(
    () => ({
      isOpen,
      toggleSidebar,
    }),
    [isOpen]
  );

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
}

function useSidebar() {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarContext.');
  }
  return context;
}

export { SidebarProvider, useSidebar };
